import { useCallback, useEffect, useMemo, useState } from "react";
import * as anchor from "@project-serum/anchor";
import styled from "styled-components";
import { Container, Snackbar } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { TokenClaimer } from "./TokenClaim";
import LinearProgress from "@mui/material/LinearProgress";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

import {
  Commitment,
  Connection,
  PublicKey,
  Transaction,
} from "@solana/web3.js";

import { useWindowSize, useWindowWidth } from "@react-hook/window-size";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import {
  awaitTransactionSignatureConfirmation,
  CANDY_MACHINE_PROGRAM,
  CandyMachineAccount,
  createAccountsForMint,
  getCandyMachineState,
  getCollectionPDA,
  mintOneToken,
  SetupState,
} from "./candy-machine";
import { AlertState, getAtaForMint, toDate } from "./utils";
import { MintButton } from "./MintButton";
import { GatewayProvider } from "@civic/solana-gateway-react";
import { sendTransaction } from "./connection";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
//@ts-ignore
import confetti from "canvas-confetti";
import { Token } from "@solana/spl-token";
import { fontStyle } from "@mui/system";
import { Link } from "@mui/material";
require("./Bg.css");

function throwConfetti(): void {
  confetti({
    particleCount: 200,
    spread: 70,
    origin: { y: 0.6 },
  });
}

const ConnectButton = styled(WalletDialogButton)`
  width: 90%;
  height: 60px;
  margin: auto;
  display: flex;
  margin-top: 10px;
  margin-bottom: 5px;
  background: #6b73a7;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 16px;
`;

const StyledPaper = styled(Paper)`
  padding: 20px;
  background-color: #252a3e;
  border-radius: 22px;
  margin: 5px;
  box-shadow: 10px 10px 10px 0px rgb(0 0 0);
  opacity: 95%;
  height: 600px;
`;

const StyledPaperLeft = styled(Paper)`
  padding: 20px;
  background-color: #252a3e;
  border-radius: 22px;
  margin: 5px;
  box-shadow: 10px 10px 10px 0px rgb(0 0 0);
  opacity: 95%;
  height: 400px;
`;

const MobilePaper = styled(Paper)`
  padding: 20px;
  background-color: #252a3e;
  border-radius: 22px;
  margin: 20px;
  box-shadow: 10px 10px 10px 0px rgb(0 0 0);
  opacity: 90%;
  // height: 575px;
`;

const StyledPaperActive = styled(Paper)`
  padding: 8px;
  background-color: #3b4261;
  border-radius: 22px;
  margin: 5px;
  box-shadow: 10px 10px 10px 0px rgb(0 0 0);
  justifycontent: "center";
  alignitems: "center";
  textalign: "center";
  verticalalign: "middle";
`;

const StyledPaperNotActive = styled(Paper)`
  padding: 6px;
  background-color: #3b4261;
  border-radius: 22px;
  margin: 12px;
  margintop: 15px;
  opacity: 30%;
`;
const MintContainer = styled.div``; // add your owns styles here

export interface HomeProps {
  candyMachineId?: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  txTimeout: number;
  rpcHost: string;
  network: WalletAdapterNetwork;
}

const Home = (props: HomeProps) => {
  const [animatedBg, setAnimatedBg] = useState(false);
  const [isUserMinting, setIsUserMinting] = useState(false);
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  // ---------- CONFIGURATION FOR PROJECTS INFO HERE --------------------

  const quickdropId1 = "3tN6jNBpJWAQyMUtyCL1CPgrRaGEP6Qg9kVK4PxGnUSa";
  const quickdropId2 = "FSEtS8tfFfNXfTAVRQRtNjUxCjD97hSaN7SofbMgpeu8";
  // const quickdropId3 = "DMTdhqubtDftLZVABS9czrphhadhPKkQijDZE1atQPRt";

  const totalSupply = "2500";

  const phaseOnePrice = "0";
  const phaseOneName = "Selected Tits";
  const phaseOneTime = "December 28, 2022 11am EST";
  const phaseOneToken = "pZYfiUdMoqs7i21xtXhW2Fe16pK63nm4SuWnwrVbrpk";

   const phaseTwoPrice = "2";
   const phaseTwoName = "Whitelist";
   const phaseTwoTime = "December 28, 2022 Noon EST";
   const phaseTwoToken = "CLyGKWLAXQmdNAETj9VXPd5tdGB8Gwt16jVQ6pBM3KMW";

  // const phaseThreePrice = "3";
  // const phaseThreeName = "Cellar List";
  // const phaseThreeTime = "November 21, 2022 6pm EST";
  // const phaseThreeToken = "FkjgD2T3qk1eC9A2cMF9qxRjQThQ5sxJuWw1jfahoEE4";

  const phasePublicPrice = "2";
  const phasePublicName = "Public Sale";
  const phasePublicTime = "December 28, 2022 2pm EST";
  //dont change public token, use this one forever 4Vo9Z6krkukMhDzcXRMukKb9YooNNDgqtaKjEyH2capd
  const phasePublicToken = "4Vo9Z6krkukMhDzcXRMukKb9YooNNDgqtaKjEyH2capd";

  const firstDescription =
    "TittyDAO is a collection of 2500 passes. Holding a pass gives you access to the DAO, our platforms and any future airdrops!";
  const secondDescription = "";

  const HERO = "/hero.gif";
  const LOGO = "/logo.png";
  const [isActive, setIsActive] = useState(false);
  const [currentShift, setCurrentShift] = useState<number>(0);
  const [endDate, setEndDate] = useState<Date>();
  const [itemsRemaining, setItemsRemaining] = useState<number>();
  const [isWhitelistUser, setIsWhitelistUser] = useState(false);
  const [isPresale, setIsPresale] = useState(false);
  const [isValidBalance, setIsValidBalance] = useState(false);
  const [discountPrice, setDiscountPrice] = useState<anchor.BN>();
  const [needTxnSplit, setNeedTxnSplit] = useState(true);
  const [setupTxn, setSetupTxn] = useState<SetupState>();
  const [width, height] = useWindowSize();
  const onlyWidth = useWindowWidth();

  const [isMobile, setIsMobile] = useState<boolean>(false);
  useEffect(() => {
    setIsMobile(onlyWidth < 999);
  }, [onlyWidth]);
  const [isNotMobile, setIsNotMobile] = useState<boolean>(false);
  useEffect(() => {
    setIsNotMobile(onlyWidth > 1000);
  }, [onlyWidth]);

  const test = 1;
  const [isTest, setIsTest] = useState<boolean>(false);
  useEffect(() => {
    setIsTest(test === 1);
  }, [test]);

  const [isSoldOut, setIsSoldOut] = useState<boolean>(false);
  useEffect(() => {
    setIsSoldOut(itemsRemaining === 0);
  }, [itemsRemaining]);

  const [isPhase1, setIsPhase1] = useState<boolean>(false);
  useEffect(() => {
    setIsPhase1(
      candyMachine?.state.whitelistMintSettings?.mint.toBase58() ===
        phaseOneToken
    );
  }, [candyMachine?.state.whitelistMintSettings?.mint]);

  const [isPhase2, setIsPhase2] = useState<boolean>(false);
  useEffect(() => {
    setIsPhase2(
      candyMachine?.state.whitelistMintSettings?.mint.toBase58() ===
        phaseTwoToken
    );
  }, [candyMachine?.state.whitelistMintSettings?.mint]);

  // const [isPhase3, setIsPhase3] = useState<boolean>(false);
  // useEffect(() => {
  //   setIsPhase3(
  //     candyMachine?.state.whitelistMintSettings?.mint.toBase58() ===
  //       phaseThreeToken
  //   ); }, [candyMachine?.state.whitelistMintSettings?.mint]);

  const [isPublic, setIsPublic] = useState<boolean>(false);
  useEffect(() => {
    setIsPublic(
      candyMachine?.state.whitelistMintSettings?.mint.toBase58() ===
        phasePublicToken
    );
  }, [candyMachine?.state.whitelistMintSettings?.mint]);

  const rpcUrl = props.rpcHost;
  const wallet = useWallet();

  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;
  }, [wallet]);

  const refreshCandyMachineState = useCallback(
    async (commitment: Commitment = "confirmed") => {
      if (!anchorWallet) {
        return;
      }

      const connection = new Connection(props.rpcHost, commitment);

      if (props.candyMachineId) {
        try {
          const cndy = await getCandyMachineState(
            anchorWallet,
            props.candyMachineId,
            connection
          );

          const currentSlot = await connection.getSlot();
          const blockTime = (await connection.getBlockTime(
            currentSlot
          )) as number;
          const shift = new Date().getTime() / 1000 - blockTime;

          setCurrentShift(shift);

          let active =
            cndy?.state.goLiveDate?.toNumber() <
            new Date().getTime() / 1000 + shift;
          let presale = false;

          // duplication of state to make sure we have the right values!
          let isWLUser = false;
          let userPrice = cndy.state.price;

          // whitelist mint?
          if (cndy?.state.whitelistMintSettings) {
            // is it a presale mint?
            if (
              cndy.state.whitelistMintSettings.presale &&
              (!cndy.state.goLiveDate ||
                cndy.state.goLiveDate.toNumber() > new Date().getTime() / 1000)
            ) {
              presale = true;
            }
            // is there a discount?
            if (cndy.state.whitelistMintSettings.discountPrice) {
              setDiscountPrice(cndy.state.whitelistMintSettings.discountPrice);
              userPrice = cndy.state.whitelistMintSettings.discountPrice;
            } else {
              setDiscountPrice(undefined);
              // when presale=false and discountPrice=null, mint is restricted
              // to whitelist users only
              if (!cndy.state.whitelistMintSettings.presale) {
                cndy.state.isWhitelistOnly = true;
              }
            }
            // retrieves the whitelist token
            const mint = new anchor.web3.PublicKey(
              cndy.state.whitelistMintSettings.mint
            );
            const token = (
              await getAtaForMint(mint, anchorWallet.publicKey)
            )[0];

            try {
              const balance = await connection.getTokenAccountBalance(token);
              isWLUser = parseInt(balance.value.amount) > 0;
              // only whitelist the user if the balance > 0
              setIsWhitelistUser(isWLUser);

              if (cndy.state.isWhitelistOnly) {
                active = isWLUser && (presale || active);
              }
            } catch (e) {
              setIsWhitelistUser(false);
              // no whitelist user, no mint
              if (cndy.state.isWhitelistOnly) {
                active = false;
              }
              console.log(
                "There was a problem fetching whitelist token balance"
              );
              console.log(e);
            }
          }
          userPrice = isWLUser ? userPrice : cndy.state.price;

          if (cndy?.state.tokenMint) {
            // retrieves the SPL token
            const mint = new anchor.web3.PublicKey(cndy.state.tokenMint);
            const token = (
              await getAtaForMint(mint, anchorWallet.publicKey)
            )[0];
            try {
              const balance = await connection.getTokenAccountBalance(token);

              const valid = new anchor.BN(balance.value.amount).gte(userPrice);

              // only allow user to mint if token balance >  the user if the balance > 0
              setIsValidBalance(valid);
              active = active && valid;
            } catch (e) {
              setIsValidBalance(false);
              active = false;
              // no whitelist user, no mint
              console.log("There was a problem fetching SPL token balance");
              console.log(e);
            }
          } else {
            const balance = new anchor.BN(
              await connection.getBalance(anchorWallet.publicKey)
            );
            const valid = balance.gte(userPrice);
            setIsValidBalance(valid);
            active = active && valid;
          }

          // datetime to stop the mint?
          if (cndy?.state.endSettings?.endSettingType.date) {
            setEndDate(toDate(cndy.state.endSettings.number));
            if (
              cndy.state.endSettings.number.toNumber() <
              new Date().getTime() / 1000
            ) {
              active = false;
            }
          }
          // amount to stop the mint?
          if (cndy?.state.endSettings?.endSettingType.amount) {
            let limit = Math.min(
              cndy.state.endSettings.number.toNumber(),
              cndy.state.itemsAvailable
            );
            if (cndy.state.itemsRedeemed < limit) {
              setItemsRemaining(limit - cndy.state.itemsRedeemed);
            } else {
              setItemsRemaining(0);
              cndy.state.isSoldOut = true;
            }
          } else {
            setItemsRemaining(cndy.state.itemsRemaining);
          }

          if (cndy.state.isSoldOut) {
            active = false;
          }

          const [collectionPDA] = await getCollectionPDA(props.candyMachineId);
          const collectionPDAAccount = await connection.getAccountInfo(
            collectionPDA
          );

          setIsActive((cndy.state.isActive = active));
          setIsPresale((cndy.state.isPresale = presale));
          setCandyMachine(cndy);

          const txnEstimate =
            892 +
            (!!collectionPDAAccount && cndy.state.retainAuthority ? 182 : 0) +
            (cndy.state.tokenMint ? 66 : 0) +
            (cndy.state.whitelistMintSettings ? 34 : 0) +
            (cndy.state.whitelistMintSettings?.mode?.burnEveryTime ? 34 : 0) +
            (cndy.state.gatekeeper ? 33 : 0) +
            (cndy.state.gatekeeper?.expireOnUse ? 66 : 0);

          setNeedTxnSplit(txnEstimate > 1230);
        } catch (e) {
          if (e instanceof Error) {
            if (
              e.message === `Account does not exist ${props.candyMachineId}`
            ) {
              setAlertState({
                open: true,
                message: ``,
                severity: "error",
                hideDuration: null,
              });
            } else if (
              e.message.startsWith("failed to get info about account")
            ) {
              setAlertState({
                open: true,
                message: ``,
                severity: "error",
                hideDuration: null,
              });
            }
          } else {
            setAlertState({
              open: true,
              message: `${e}`,
              severity: "error",
              hideDuration: null,
            });
          }
          console.log(e);
        }
      } else {
        setAlertState({
          open: true,
          message: ``,
          severity: "error",
          hideDuration: null,
        });
      }
    },
    [anchorWallet, props.candyMachineId, props.rpcHost]
  );

  const onMint = async (
    beforeTransactions: Transaction[] = [],
    afterTransactions: Transaction[] = []
  ) => {
    try {
      setIsUserMinting(true);
      document.getElementById("#identity")?.click();
      if (wallet.connected && candyMachine?.program && wallet.publicKey) {
        let setupMint: SetupState | undefined;
        if (needTxnSplit && setupTxn === undefined) {
          setAlertState({
            open: true,
            message: "Please sign account setup transaction",
            severity: "info",
          });
          setupMint = await createAccountsForMint(
            candyMachine,
            wallet.publicKey
          );
          let status: any = { err: true };
          if (setupMint.transaction) {
            status = await awaitTransactionSignatureConfirmation(
              setupMint.transaction,
              props.txTimeout,
              props.connection,
              true
            );
          }
          if (status && !status.err) {
            setSetupTxn(setupMint);
            setAlertState({
              open: true,
              message:
                "Setup transaction succeeded! Please sign minting transaction",
              severity: "info",
            });
          } else {
            setAlertState({
              open: true,
              message: "Mint failed! Please try again!",
              severity: "error",
            });
            setIsUserMinting(false);
            return;
          }
        } else {
          setAlertState({
            open: true,
            message: "Please sign minting transaction",
            severity: "info",
          });
        }

        let mintResult = await mintOneToken(
          candyMachine,
          wallet.publicKey,
          beforeTransactions,
          afterTransactions,
          setupMint ?? setupTxn
        );

        let status: any = { err: true };
        let metadataStatus = null;
        if (mintResult) {
          status = await awaitTransactionSignatureConfirmation(
            mintResult.mintTxId,
            props.txTimeout,
            props.connection,
            true
          );

          metadataStatus =
            await candyMachine.program.provider.connection.getAccountInfo(
              mintResult.metadataKey,
              "processed"
            );
          console.log("Metadata status: ", !!metadataStatus);
        }

        if (status && !status.err && metadataStatus) {
          // manual update since the refresh might not detect
          // the change immediately
          let remaining = itemsRemaining! - 1;
          setItemsRemaining(remaining);
          setIsActive((candyMachine.state.isActive = remaining > 0));
          candyMachine.state.isSoldOut = remaining === 0;
          setSetupTxn(undefined);
          setAlertState({
            open: true,
            message: "Mint succeeded!",
            severity: "success",
            hideDuration: 7000,
          });
          refreshCandyMachineState("processed");
        } else if (status && !status.err) {
          setAlertState({
            open: true,
            message:
              "Mint likely failed! Anti-bot SOL 0.01 fee potentially charged! Check the explorer to confirm the mint failed and if so, make sure you are eligible to mint before trying again.",
            severity: "error",
            hideDuration: 8000,
          });
          refreshCandyMachineState();
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
          refreshCandyMachineState();
        }
      }
    } catch (error: any) {
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (!error.message) {
          message = "Transaction timeout! Please try again.";
        } else if (error.message.indexOf("0x137")) {
          console.log(error);
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          console.log(error);
          message = `SOLD OUT!`;
          window.location.reload();
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
      // updates the candy machine state to reflect the latest
      // information on chain
      refreshCandyMachineState();
    } finally {
      setIsUserMinting(false);
    }
  };

  const toggleMintButton = () => {
    let active = !isActive || isPresale;

    if (active) {
      if (candyMachine!.state.isWhitelistOnly && !isWhitelistUser) {
        active = false;
      }
      if (endDate && Date.now() >= endDate.getTime()) {
        active = false;
      }
    }

    if (
      isPresale &&
      candyMachine!.state.goLiveDate &&
      candyMachine!.state.goLiveDate.toNumber() <= new Date().getTime() / 1000
    ) {
      setIsPresale((candyMachine!.state.isPresale = false));
    }

    setIsActive((candyMachine!.state.isActive = active));
  };

  useEffect(() => {
    refreshCandyMachineState();
  }, [
    anchorWallet,
    props.candyMachineId,
    props.connection,
    refreshCandyMachineState,
  ]);

  useEffect(() => {
    (function loop() {
      setTimeout(() => {
        refreshCandyMachineState();
        loop();
      }, 20000);
    })();
  }, [refreshCandyMachineState]);

  return (
    <>
      {animatedBg && (
        <div className="section section--featured">
          <div className="row-container">
            <div className="line">
              <div>
                <div className="row">
                  <img src="line-3-min.png" alt="" />
                </div>
                <div className="row">
                  <img src="line-3-min.png" alt="" />
                </div>
              </div>
            </div>

            <div className="line second">
              <div>
                <div className="row">
                  <img src="line-1-min.png" alt="" />
                </div>
                <div className="row">
                  <img src="line-1-min.png" alt="" />
                </div>
              </div>
            </div>
            <div className="line third">
              <div>
                <div className="row">
                  <img src="line-3-min.png" alt="" />
                </div>
                <div className="row">
                  <img src="line-3-min.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="shadow">
            <span></span>
          </div>
        </div>
      )}

      {isNotMobile && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Box
            style={{
              display: "flex",
              marginLeft: "50px",
              marginRight: "50px",
              marginTop: "20px",
              maxWidth: "1350px",
            }}
            alignItems="center"
          >
            <Grid container spacing={3}>
              <Grid item xs={7}>
                <StyledPaperLeft>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Grid item xs={12}>
                        <div
                          style={{
                            borderRadius: "20px",
                            overflow: "hidden",
                            marginRight: "0px",
                            marginTop: "15px",
                          }}
                        >
                          <img
                            src={LOGO}
                            alt=""
                            width="100%"
                            style={{ borderRadius: "5px" }}
                          />
                        </div>
                      </Grid>

                      {/* <Grid item xs={12}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <IconButton color="inherit" aria-label="upload picture" component="label">
                      <input hidden accept="image/*" type="file" />
                      <PhotoCamera />
                    </IconButton>
                    <IconButton color="inherit" aria-label="twitter" component="label" size="large">
                      <TwitterIcon />
                    </IconButton>
                  </Stack>
                </Grid>   */}

                      <Grid item xs={12}>
                        <div
                          style={{
                            borderRadius: "20px",
                            overflow: "hidden",
                            marginRight: "15px",
                            marginTop: "15px",
                          }}
                        >
                          <h3
                            style={{
                              fontSize: "20px",
                            }}
                          >
                            {firstDescription}
                          </h3>
                        </div>
                      </Grid>
                      {/* <Grid item xs={12}>
                        <div
                          style={{
                            borderRadius: "20px",
                            overflow: "hidden",
                            marginRight: "15px",
                            marginTop: "10px",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "19px",
                            }}
                          >
                            <Link
                              underline="none"
                              href="https://m00nshine-industries.gitbook.io/m00nshine-industries/"
                            >
                              Learn more on Gitbook
                            </Link>
                          </Typography>
                        </div>
                      </Grid> */}
                      <Grid item xs={12}>
                        <div
                          style={{
                            borderRadius: "20px",
                            overflow: "hidden",
                            marginRight: "15px",
                            marginTop: "10px",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "17px",
                            }}
                          >
                            <Link
                              underline="none"
                              href="https://twitter.com/TittyDAO"
                            >
                              Follow them on Twitter
                            </Link>
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <div
                        style={{
                          borderRadius: "20px",
                          overflow: "hidden",
                          marginRight: "5px",
                          marginTop: "15px",
                        }}
                      >
                        <img
                          src={HERO}
                          alt=""
                          width="100%"
                          style={{ borderRadius: "5px" }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </StyledPaperLeft>
              </Grid>

              {/* CANDY MACHINE */}

              <Grid item xs={5}>
                <StyledPaper>
                  {/* PHASE 1 */}
                  <Grid item xs={12}>
                    {isPhase1 ? (
                      <StyledPaperActive>
                        <Box m="auto">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              padding: "5px",
                            }}
                          >
                            <h4
                              style={{
                                margin: "2px",
                              }}
                            >
                              {phaseOneName} - {phaseOnePrice}◎
                            </h4>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <h5
                              style={{
                                margin: "2px",
                              }}
                            >
                              {phaseOneTime}
                            </h5>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <TokenClaimer quickdropId={quickdropId1} />
                          </div>
                        </Box>
                      </StyledPaperActive>
                    ) : (
                      <StyledPaperNotActive>
                        <Box m="auto">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              padding: "5px",
                            }}
                          >
                            <h4
                              style={{
                                margin: "2px",
                              }}
                            >
                              {phaseOneName} - {phaseOnePrice}◎
                            </h4>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <h5
                              style={{
                                margin: "2px",
                              }}
                            >
                              {phaseOneTime}
                            </h5>
                          </div>
                        </Box>
                      </StyledPaperNotActive>
                    )}
                  </Grid>

                  {/* PHASE 2 */}
                  <Grid item xs={12}>
                    {isPhase2 ? (
                      <StyledPaperActive>
                        <Box m="auto">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              padding: "5px",
                            }}
                          >
                            <h4
                              style={{
                                margin: "2px",
                              }}
                            >
                              {phaseTwoName} - {phaseTwoPrice}◎
                            </h4>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <h5
                              style={{
                                margin: "2px",
                              }}
                            >
                              {phaseTwoTime}
                            </h5>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <TokenClaimer quickdropId={quickdropId2} />
                          </div>
                        </Box>
                      </StyledPaperActive>
                    ) : (
                      <StyledPaperNotActive>
                        <Box m="auto">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              padding: "5px",
                            }}
                          >
                            <h4
                              style={{
                                margin: "2px",
                              }}
                            >
                              {phaseTwoName} - {phaseTwoPrice}◎
                            </h4>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <h5
                              style={{
                                margin: "2px",
                              }}
                            >
                              {phaseTwoTime}
                            </h5>
                          </div>
                        </Box>
                      </StyledPaperNotActive>
                    )}
                  </Grid>

                  {/* PHASE 3 */}
                  {/* <Grid item xs={12}>
                    {isPhase3 ? (
                      <StyledPaperActive>
                        <Box m="auto">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              padding: "5px",
                            }}
                          >
                            <h4
                              style={{
                                margin: "2px",
                              }}
                            >
                              {phaseThreeName} - {phaseThreePrice}◎
                            </h4>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <h5
                              style={{
                                margin: "2px",
                              }}
                            >
                              {phaseThreeTime}
                            </h5>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <TokenClaimer quickdropId={quickdropId3} />
                          </div>
                        </Box>
                      </StyledPaperActive>
                    ) : (
                      <StyledPaperNotActive>
                        <Box m="auto">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              padding: "5px",
                            }}
                          >
                            <h4
                              style={{
                                margin: "2px",
                              }}
                            >
                              {phaseThreeName} - {phaseThreePrice}◎
                            </h4>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <h5
                              style={{
                                margin: "2px",
                              }}
                            >
                              {phaseThreeTime}
                            </h5>
                          </div>
                        </Box>
                      </StyledPaperNotActive>
                    )}
                  </Grid> */}

                  {/* PUBLIC PHASE AND COUNTDOWN*/}
                  <Grid item xs={12}>
                    {isPublic ? (
                      <StyledPaperActive>
                        <Box m="auto">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              padding: "5px",
                            }}
                          >
                            <h4
                              style={{
                                margin: "2px",
                              }}
                            >
                              {phasePublicName} - {phasePublicPrice}◎
                            </h4>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <h5
                              style={{
                                margin: "2px",
                              }}
                            >
                              {phasePublicTime}
                            </h5>
                          </div>
                        </Box>
                      </StyledPaperActive>
                    ) : (
                      <StyledPaperNotActive>
                        <Box m="auto">
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  padding: "5px",
                                }}
                              >
                                <h4
                                  style={{
                                    margin: "2px",
                                  }}
                                >
                                  {phasePublicName} - {phasePublicPrice}◎
                                </h4>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <h5
                                  style={{
                                    margin: "2px",
                                  }}
                                >
                                  {phasePublicTime}
                                </h5>
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </StyledPaperNotActive>
                    )}
                  </Grid>
                  <Box
                    sx={{
                      marginTop: "35px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "left",
                            }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                fontWeight: "bold",
                                color: "#ffffff",
                                fontSize: "17px",
                              }}
                            >
                              {`${
                                Number(totalSupply) - Number(itemsRemaining)
                              } / ${totalSupply} Minted`}
                            </Typography>
                          </div>
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Box>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "right",
                            }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                fontWeight: "bold",
                                color: "#ffffff",
                                fontSize: "14px",
                              }}
                            >
                              {`${(
                                ((Number(totalSupply) -
                                  Number(itemsRemaining)) /
                                  Number(totalSupply)) *
                                100
                              ).toPrecision(3)}%`}
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box sx={{ width: "100%", marginTop: "2px" }}>
                    <LinearProgress
                      color="inherit"
                      variant="determinate"
                      value={
                        ((Number(totalSupply) - Number(itemsRemaining)) /
                          Number(totalSupply)) *
                        100
                      }
                    />
                  </Box>

                  <Box
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={7}>
                        {!wallet.connected ? (
                          <ConnectButton>Connect Wallet</ConnectButton>
                        ) : (
                          <>
                            {candyMachine && (
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                wrap="nowrap"
                              ></Grid>
                            )}
                            <Box m="auto">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <MintContainer>
                                  {candyMachine?.state.isActive &&
                                  candyMachine?.state.gatekeeper &&
                                  wallet.publicKey &&
                                  wallet.signTransaction ? (
                                    //@ts-ignore
                                    <GatewayProvider
                                      wallet={{
                                        publicKey:
                                          wallet.publicKey ||
                                          new PublicKey(CANDY_MACHINE_PROGRAM),
                                        //@ts-ignore
                                        signTransaction: wallet.signTransaction,
                                      }}
                                      gatekeeperNetwork={
                                        candyMachine?.state?.gatekeeper
                                          ?.gatekeeperNetwork
                                      }
                                      clusterUrl={
                                        props.network ===
                                        WalletAdapterNetwork.Devnet
                                          ? "https://api.devnet.solana.com"
                                          : rpcUrl
                                      }
                                      handleTransaction={async (
                                        transaction: Transaction
                                      ) => {
                                        setIsUserMinting(true);
                                        const userMustSign =
                                          transaction.signatures.find((sig) =>
                                            sig.publicKey.equals(
                                              wallet.publicKey!
                                            )
                                          );
                                        if (userMustSign) {
                                          setAlertState({
                                            open: true,
                                            message:
                                              "Please sign one-time Civic Pass issuance",
                                            severity: "info",
                                          });
                                          try {
                                            transaction =
                                              await wallet.signTransaction!(
                                                transaction
                                              );
                                          } catch (e) {
                                            setAlertState({
                                              open: true,
                                              message: "User cancelled signing",
                                              severity: "error",
                                            });
                                            // setTimeout(() => window.location.reload(), 2000);
                                            setIsUserMinting(false);
                                            throw e;
                                          }
                                        } else {
                                          setAlertState({
                                            open: true,
                                            message: "Refreshing Civic Pass",
                                            severity: "info",
                                          });
                                        }
                                        try {
                                          await sendTransaction(
                                            props.connection,
                                            wallet,
                                            transaction,
                                            [],
                                            true,
                                            "confirmed"
                                          );
                                          setAlertState({
                                            open: true,
                                            message: "Please sign minting",
                                            severity: "info",
                                          });
                                        } catch (e) {
                                          setAlertState({
                                            open: true,
                                            message:
                                              "Solana dropped the transaction, please try again",
                                            severity: "warning",
                                          });
                                          console.error(e);
                                          // setTimeout(() => window.location.reload(), 2000);
                                          setIsUserMinting(false);
                                          throw e;
                                        }
                                        await onMint();
                                      }}
                                      broadcastTransaction={false}
                                      options={{ autoShowModal: false }}
                                    >
                                      <MintButton
                                        candyMachine={candyMachine}
                                        isMinting={isUserMinting}
                                        setIsMinting={(val) =>
                                          setIsUserMinting(val)
                                        }
                                        onMint={onMint}
                                        isActive={
                                          isActive ||
                                          (isPresale &&
                                            isWhitelistUser &&
                                            isValidBalance)
                                        }
                                      />
                                    </GatewayProvider>
                                  ) : (
                                    <MintButton
                                      candyMachine={candyMachine}
                                      isMinting={isUserMinting}
                                      setIsMinting={(val) =>
                                        setIsUserMinting(val)
                                      }
                                      onMint={onMint}
                                      isActive={
                                        isActive ||
                                        (isPresale &&
                                          isWhitelistUser &&
                                          isValidBalance)
                                      }
                                    />
                                  )}
                                </MintContainer>
                              </div>
                            </Box>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={5}>
                        <Box
                          sx={{
                            display: "flex",
                            margin: "auto",
                          }}
                        >
                          <CrossmintPayButton
                            clientId="688a2e0f-e375-40f5-a48b-a3d429e11d29"
                            mintConfig={{ type: "candy-machine" }}
                            className="my-custom-crossmint-button"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </StyledPaper>
              </Grid>
            </Grid>
          </Box>
        </div>
      )}

      {isMobile && (
        <Box
          style={{ minHeight: "100vh", display: "flex" }}
          alignItems="center"
        >
          <Container maxWidth="xs" style={{ position: "relative" }}>
            <MobilePaper>
              {" "}
              <img
                src={LOGO}
                alt=""
                width="100%"
                style={{ borderRadius: "5px" }}
              />
            </MobilePaper>
            <MobilePaper>
              <div>
                <img
                  src={HERO}
                  alt=""
                  width="100%"
                  style={{ borderRadius: "20px", margin: "auto" }}
                />
              </div>
            </MobilePaper>

            <MobilePaper>
              <Grid item xs={12}>
                {/* PHASE 1 */}
                <Grid item xs={12}>
                  {isPhase1 ? (
                    <StyledPaperActive>
                      <Box m="auto">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "5px",
                          }}
                        >
                          <h4
                            style={{
                              margin: "2px",
                            }}
                          >
                            {phaseOneName} - {phaseOnePrice}◎
                          </h4>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <h5
                            style={{
                              margin: "2px",
                              fontSize: "13px",
                            }}
                          >
                            Ends {phaseTwoTime}
                          </h5>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <TokenClaimer quickdropId={quickdropId1} />
                        </div>
                      </Box>
                    </StyledPaperActive>
                  ) : (
                    <div></div>
                  )}
                </Grid>

                {/* PHASE 2 */}
                <Grid item xs={12}>
                  {isPhase2 ? (
                    <StyledPaperActive>
                      <Box m="auto">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "5px",
                          }}
                        >
                          <h4
                            style={{
                              margin: "2px",
                            }}
                          >
                            {phaseTwoName} - {phaseTwoPrice}◎
                          </h4>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <h5
                            style={{
                              margin: "2px",
                              fontSize: "13px",
                            }}
                          >
                            Ends {phasePublicTime}
                          </h5>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <TokenClaimer quickdropId={quickdropId2} />
                        </div>
                      </Box>
                    </StyledPaperActive>
                  ) : (
                    <div></div>
                  )}
                </Grid>

                {/* PHASE 3 */}
                {/* <Grid item xs={12}>
                  {isPhase3 ? (
                    <StyledPaperActive>
                      <Box m="auto">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "5px",
                          }}
                        >
                          <h4
                            style={{
                              margin: "2px",
                            }}
                          >
                            {phaseThreeName} - {phaseThreePrice}◎
                          </h4>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <h5
                            style={{
                              margin: "2px",
                              fontSize: "13px",
                            }}
                          >
                            Ends {phasePublicTime}
                          </h5>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <TokenClaimer quickdropId={quickdropId3} />
                        </div>
                      </Box>
                    </StyledPaperActive>
                  ) : (
                    <div></div>
                  )}
                </Grid> */}

                {/* PUBLIC SALE */}
                <Grid item xs={12}>
                  {isPublic ? (
                    <StyledPaperActive>
                      <Box m="auto">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "5px",
                          }}
                        >
                          <h4
                            style={{
                              margin: "2px",
                            }}
                          >
                            {phasePublicName} - {phasePublicPrice}◎
                          </h4>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <h5
                            style={{
                              margin: "2px",
                              fontSize: "13px",
                            }}
                          >
                            Currently Minting
                          </h5>
                        </div>
                      </Box>
                    </StyledPaperActive>
                  ) : (
                    <div></div>
                  )}
                </Grid>

                {/* PROGRESS BAR */}
                <Box
                  sx={{
                    marginTop: "20px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                          }}
                        >
                          <Typography
                            variant="h6"
                            style={{
                              fontWeight: "bold",
                              color: "#ffffff",
                              fontSize: "17px",
                            }}
                          >
                            {`${
                              Number(totalSupply) - Number(itemsRemaining)
                            } / ${totalSupply} Minted`}
                          </Typography>
                        </div>
                      </Box>
                    </Grid>

                    <Grid item xs={6}>
                      <Box>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "right",
                          }}
                        >
                          <Typography
                            variant="h6"
                            style={{
                              fontWeight: "bold",
                              color: "#ffffff",
                              fontSize: "14px",
                            }}
                          >
                            {`${(
                              ((Number(totalSupply) - Number(itemsRemaining)) /
                                Number(totalSupply)) *
                              100
                            ).toPrecision(3)}%`}
                          </Typography>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ width: "100%", marginTop: "2px" }}>
                  <LinearProgress
                    color="inherit"
                    variant="determinate"
                    value={
                      ((Number(totalSupply) - Number(itemsRemaining)) /
                        Number(totalSupply)) *
                      100
                    }
                  />
                </Box>

                <Box
                  style={{
                    marginTop: "15px",
                  }}
                >
                  <Grid item xs={12}>
                    {!wallet.connected ? (
                      <ConnectButton>Connect Wallet</ConnectButton>
                    ) : (
                      <>
                        {candyMachine && (
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            wrap="nowrap"
                          >
                            {/* <Grid item xs={12}>
                      {isActive && endDate && Date.now() < endDate.getTime() ? (
                        <>
                          <MintCountdown
                            key="endSettings"
                            date={getCountdownDate(candyMachine, currentShift)}
                            style={{ justifyContent: "flex-end" }}
                            status="COMPLETED"
                            onComplete={toggleMintButton}
                          />
                          <Typography
                            variant="caption"
                            align="center"
                            display="block"
                            style={{ fontWeight: "bold" }}
                          >
                            TO END OF MINT
                          </Typography>
                        </>
                      ) : (
                        <>
                          <MintCountdown
                            key="goLive"
                            date={getCountdownDate(candyMachine, currentShift)}
                            style={{ justifyContent: "flex-end" }}
                            status={
                              candyMachine?.state?.isSoldOut ||
                              (endDate && Date.now() > endDate.getTime())
                                ? "COMPLETED"
                                : isPresale
                                ? "PRESALE"
                                : "LIVE"
                            }
                            onComplete={toggleMintButton}
                          />
                          {isPresale &&
                            candyMachine.state.goLiveDate &&
                            candyMachine.state.goLiveDate.toNumber() >
                              new Date().getTime() / 1000 + currentShift && (
                              <Typography
                                variant="caption"
                                align="center"
                                display="block"
                                style={{ fontWeight: "bold" }}
                              >
                                UNTIL PUBLIC MINT
                              </Typography>
                            )}
                        </>
                      )}
                    </Grid> */}
                          </Grid>
                        )}

                        <Box m="auto">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <MintContainer>
                              {candyMachine?.state.isActive &&
                              candyMachine?.state.gatekeeper &&
                              wallet.publicKey &&
                              wallet.signTransaction ? (
                                //@ts-ignore
                                <GatewayProvider
                                  wallet={{
                                    publicKey:
                                      wallet.publicKey ||
                                      new PublicKey(CANDY_MACHINE_PROGRAM),
                                    //@ts-ignore
                                    signTransaction: wallet.signTransaction,
                                  }}
                                  gatekeeperNetwork={
                                    candyMachine?.state?.gatekeeper
                                      ?.gatekeeperNetwork
                                  }
                                  clusterUrl={
                                    props.network ===
                                    WalletAdapterNetwork.Devnet
                                      ? "https://api.devnet.solana.com"
                                      : rpcUrl
                                  }
                                  handleTransaction={async (
                                    transaction: Transaction
                                  ) => {
                                    setIsUserMinting(true);
                                    const userMustSign =
                                      transaction.signatures.find((sig) =>
                                        sig.publicKey.equals(wallet.publicKey!)
                                      );
                                    if (userMustSign) {
                                      setAlertState({
                                        open: true,
                                        message:
                                          "Please sign one-time Civic Pass issuance",
                                        severity: "info",
                                      });
                                      try {
                                        transaction =
                                          await wallet.signTransaction!(
                                            transaction
                                          );
                                      } catch (e) {
                                        setAlertState({
                                          open: true,
                                          message: "User cancelled signing",
                                          severity: "error",
                                        });
                                        // setTimeout(() => window.location.reload(), 2000);
                                        setIsUserMinting(false);
                                        throw e;
                                      }
                                    } else {
                                      setAlertState({
                                        open: true,
                                        message: "Refreshing Civic Pass",
                                        severity: "info",
                                      });
                                    }
                                    try {
                                      await sendTransaction(
                                        props.connection,
                                        wallet,
                                        transaction,
                                        [],
                                        true,
                                        "confirmed"
                                      );
                                      setAlertState({
                                        open: true,
                                        message: "Please sign minting",
                                        severity: "info",
                                      });
                                    } catch (e) {
                                      setAlertState({
                                        open: true,
                                        message:
                                          "Solana dropped the transaction, please try again",
                                        severity: "warning",
                                      });
                                      console.error(e);
                                      // setTimeout(() => window.location.reload(), 2000);
                                      setIsUserMinting(false);
                                      throw e;
                                    }
                                    await onMint();
                                  }}
                                  broadcastTransaction={false}
                                  options={{ autoShowModal: false }}
                                >
                                  <MintButton
                                    candyMachine={candyMachine}
                                    isMinting={isUserMinting}
                                    setIsMinting={(val) =>
                                      setIsUserMinting(val)
                                    }
                                    onMint={onMint}
                                    isActive={
                                      isActive ||
                                      (isPresale &&
                                        isWhitelistUser &&
                                        isValidBalance)
                                    }
                                  />
                                </GatewayProvider>
                              ) : (
                                <MintButton
                                  candyMachine={candyMachine}
                                  isMinting={isUserMinting}
                                  setIsMinting={(val) => setIsUserMinting(val)}
                                  onMint={onMint}
                                  isActive={
                                    isActive ||
                                    (isPresale &&
                                      isWhitelistUser &&
                                      isValidBalance)
                                  }
                                />
                              )}
                            </MintContainer>
                          </div>
                        </Box>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        margin: "auto",
                      }}
                    >
                      <CrossmintPayButton
                        clientId="688a2e0f-e375-40f5-a48b-a3d429e11d29"
                        mintConfig={{ type: "candy-machine" }}
                        className="my-custom-crossmint-button"
                      />
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </MobilePaper>
          </Container>

          <Snackbar
            open={alertState.open}
            autoHideDuration={6000}
            onClose={() => setAlertState({ ...alertState, open: false })}
          >
            <Alert
              onClose={() => setAlertState({ ...alertState, open: false })}
              severity={alertState.severity}
            >
              {alertState.message}
            </Alert>
          </Snackbar>
        </Box>
      )}
    </>
  );
};

const getCountdownDate = (
  candyMachine: CandyMachineAccount,
  currentShift: number
): Date | undefined => {
  if (
    candyMachine.state.isActive &&
    candyMachine.state.endSettings?.endSettingType.date
  ) {
    return toDate(candyMachine.state.endSettings.number);
  }

  return toDate(
    candyMachine.state.goLiveDate
      ? new anchor.BN(candyMachine.state.goLiveDate.toNumber() + currentShift)
      : candyMachine.state.isPresale
      ? new anchor.BN(new Date().getTime() / 1000)
      : undefined
  );
};

export default Home;
