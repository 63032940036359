import { NotifyType, useTokenClaimer } from "@jeeh/tokenclaim-ui";
import { CircularProgress, Typography } from "@material-ui/core";
import { useWallet } from "@solana/wallet-adapter-react";
import { useCallback, useEffect, useState } from "react";
import { TokenButton } from "./MintButton";

export const TokenClaimer = ({quickdropId}:{quickdropId: string}) =>  { 
  const { publicKey, signAllTransactions } = useWallet();
  

  const amount = 10

  const notify = (msg: string, notifyType: NotifyType) => {
    console.log({ msg, notifyType });
  };
  const {
    availableAmount,
    totalWhitelistedAmount,
    claimedAmount,
    onClick,
    isFetching,
    isExecuting,
    isError,
  } = useTokenClaimer(
    {
      quickdropId, /// replace this with your QuickDrop ID
      apiBaseUrl: "https://quickdrop.neft.world",
      solanaRpcHost: process.env.REACT_APP_SOLANA_RPC_HOST!,
    },
    notify,
    publicKey?.toBase58(),
    signAllTransactions,

  );

  const userWallet = publicKey?.toBase58();

  const [highlight, setHighlight] = useState<boolean>(false);
  const [lastClaimedAmount, setLastClaimedAmount] = useState<string>();

  const [isWL, setIsWL] = useState <boolean>(false)
  useEffect(()=>{setIsWL(amount<1)},[amount])

  const processHighlight = useCallback(() => {
    const isFirstLoad = lastClaimedAmount === undefined;
    setHighlight(!isFirstLoad);
    setLastClaimedAmount(claimedAmount.toString());
    return isFirstLoad;
  }, [lastClaimedAmount, claimedAmount]);

  useEffect(() => {
    let active = true;
    const isFirstLoad = processHighlight();

    if (!isFirstLoad) {
      setTimeout(() => {
        active && setHighlight(false);
      }, 350);
    }
    return () => {
      active = false;
    };
  }, [claimedAmount]);

  return (
    <div>
      <div>
        {isError ? (
          <div></div>
        ) : isFetching || isExecuting ? (
          <CircularProgress />
        ) : totalWhitelistedAmount > 0 ? (
          <div>
            <TokenButton
              disabled={availableAmount === BigInt(0)}
              onClick={onClick}
            >
              Claim {`${availableAmount}`} Tokens
            </TokenButton>
            {/* <p>
              <Typography variant="body1">Claimed</Typography>
              <Typography variant="h2" color={highlight ? "error" : "primary"}>
                {claimedAmount.toString()}
              </Typography>{" "}
            </p>
            <Typography variant="body1">
              Remaining
              <Typography variant="h2" color={highlight ? "error" : "primary"}>
                {availableAmount.toString()}{" "}
              </Typography>
            </Typography> */}
          </div>
        ) : (
          <div>
            <Typography>
              This wallet is not whitelisted!
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
